$break-xs: 320px;
$break-sm: 600px;
$break-md: 960px;
$break-lg: 1280px;
$break-xl: 1920px;

// White
$white: #FFFFFF;
$white-transparent: rgba(255, 255, 255, .8);

// Orange
$orange: #F57C00;
$orange-2: #FF5722;
$dark-orange: #E64A19;

// Blue
$blue: #0FACCF;
$dark-blue: #105778;

// Yellow
$yellow: #FF9800;
$light-yellow: #F3B762;

// Grey
$dark-grey: #212121;
$dark-grey-transparent: #212121dd;
$dark-grey-2: #313131;
$grey: #3E3E3E;
$light-grey: #727272;
$light-grey-2: #B6B6B6;
$light-grey-3: #EBEBEB;

// Black
$black: #000000;
$black-transparent: rgba(0, 0, 0, .9);

// Gradients
$gradient-black: linear-gradient( to top, $black-transparent, transparent, transparent, transparent, transparent );

// Color Variables

$light-grey: #f9f9f9; // Light grey background color for the form
$button-bg-color: #1976d2; // Primary button background color (blue)
$button-text-color: white; // Button text color (white for contrast)
$yellow: #ffeb3b; // Yellow color for any additional elements or highlights
$green: #4caf50; // Green color for other buttons or accents if needed
$primary-blue: #2196f3; // A primary blue color for links or highlights
