@mixin typo($name, $style, $weight, $src, $format) {
	@font-face {
		font-family: '#{$name}';
		font-style: $style;
		font-weight: $weight;
		src: url('../assets/fonts/#{$src}') format('#{$format}'); } }

@mixin face($name, $font, $size, $family) {
	.ty-face-#{$name} {
		font-family: '#{$font}';
		font-size: #{$size}; } }

@include typo('Roboto', normal, lighter, 'Roboto-Light.ttf', 'truetype');
@include typo('Roboto', normal, normal, 'Roboto-Regular.ttf', 'truetype');
@include typo('Roboto', normal, bold, 'Roboto-Bold.ttf', 'truetype');
@include typo('Roboto', italic, lighter, 'Roboto-LightItalic.ttf', 'truetype');
@include typo('Roboto', italic, normal, 'Roboto-Italic.ttf', 'truetype');
@include typo('Roboto', italic, bold, 'Roboto-BoldItalic.ttf', 'truetype');

@include typo('Gotham', normal, lighter, 'Gotham-Light.otf', 'truetype');
@include typo('Gotham', normal, normal, 'Gotham-Medium.otf', 'truetype');
@include typo('Gotham', normal, bold, 'Gotham-Bold.otf', 'truetype');
@include typo('Gotham', italic, lighter, 'Gotham-LightItalic.otf', 'truetype');
@include typo('Gotham', italic, normal, 'Gotham-LightItalic.otf', 'truetype');
@include typo('Gotham', italic, bold, 'Gotham-BoldItalic.otf', 'truetype');
